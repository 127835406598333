import Navigation from "@crafthunt-layout/Navigation/Navigation";
import Head, { HeadProps } from "@crafthunt-ui/Head";
import clsx from "clsx";
import { ReactNode } from "react";
import { StoryblokSettingsDef } from "types/storyblok.types";

export enum EBgColor {
  LIGHT_GRAY = "light-gray",
  DARK_GRAY = "dark-gray",
  WHITE = "white",
}

type LayoutSimpleProps = {
  children: ReactNode;
  bgColor?: EBgColor;
  locale?: string;
  isFullscreen?: boolean;
  settings?: StoryblokSettingsDef;
} & HeadProps;

const LayoutSimple = ({
  children,
  bgColor = EBgColor.WHITE,
  isFullscreen,
  locale,
  settings,
  meta,
  translatedSlugs,
  hideFromRobots,
}: LayoutSimpleProps) => {
  return (
    <>
      <Head
        meta={meta}
        locale={locale}
        translatedSlugs={translatedSlugs}
        hideFromRobots={hideFromRobots}
      />
      <div
        className={clsx("min-h-dvh", {
          "bg-gray-50 lg:bg-gray-900": bgColor === EBgColor.DARK_GRAY,
          "bg-gray-50": bgColor === EBgColor.LIGHT_GRAY,
          "bg-white": bgColor === EBgColor.WHITE,
        })}
      >
        <main
          className={clsx({
            "min-h-full flex flex-col justify-center pb-12": !isFullscreen,
          })}
        >
          <Navigation
            locale={locale}
            translatedSlugs={translatedSlugs}
            settings={settings}
          />
          <div
            className={clsx({
              "mb-10 lg:mb-28 lg:mt-8": !isFullscreen,
            })}
          >
            <div
              className={clsx({
                "bg-gray-50 lg:bg-white py-8 px-4 sm:px-10 sm:mx-auto lg:max-w-4xl lg:shadow lg:rounded-lg":
                  !isFullscreen,
              })}
            >
              {children}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default LayoutSimple;
